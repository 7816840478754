
import { defineComponent } from "vue";
import Button from "primevue/button";
import Slider from "../components/Slider.vue";
import * as FacebookServices from "../api/helpers/Facebook";

export default defineComponent({
  name: "Approved",
  components: {
    Button,
    Slider,
  },
  data() {
    return {
      f: {
        loan: 20000,
        period: 1,
      },
    };
  },
  mounted() {
    FacebookServices.pageView();
    document.title = process.env.VUE_APP_TITLE + " | Prestamos Aprobado";
    window.scrollTo(0, 0);
  },
  methods: {
    back() {
      sessionStorage.setItem("data", JSON.stringify(this.f));
      this.$router.push("/");
    },
    go() {
      sessionStorage.setItem("data", JSON.stringify(this.f));
      this.$router.push("/fintonic");
    },
    setValue(obj: any) {
      Object.assign(this.f, { [obj.answerKey]: obj.value });
    },
  },
});
